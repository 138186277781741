.inner-wrapper {
  position: relative;
  padding: 5vh 2.5vw;
  max-width: 1500px;
  margin: 0px auto;
  overflow-x: hidden;
  min-width: 380px;

  @media (max-width: 768px) {
    padding: 1vh 0;
  }
}
