@font-face {
  font-family: "GWP Bold";
  src: url("../fonts/GTWalsheimPro-Bold.ttf");
}

@font-face {
  font-family: "GWP Regular";
  src: url("../fonts/GTWalsheimPro-Regular.ttf");
}

@font-face {
  font-family: "GWP Light";
  src: url("../fonts/GTWalsheimPro-Light.ttf");
}

@font-face {
  font-family: "GWP Medium";
  src: url("../fonts/GTWalsheimPro-Medium.ttf");
}
@font-face {
  font-family: "GWP Thin";
  src: url("../fonts/GTWalsheimPro-Thin.ttf");
}

body {
  font-family: "GWP Medium" !important;
  height: 100vh;
  background: url("../../public/images/PRbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  body {
    background-size: auto;
  }
}

.App {
  height: 100vh;
}

.inner-wrapper {
  position: relative;
  max-width: 1000px;
  margin: 0px auto;
  overflow-x: hidden;
  min-width: 380px;
}

@media (max-width: 768px) {
  .inner-wrapper {
    padding: 1vh 0;
  }
}

.Calendar__yearText:last-child {
  margin-right: -0.3em;
  color: #4e7ffd !important;
}

.ant-picker-content .ant-picker-time-panel-column {
  border-inline-start: 1px solid transparent !important;
}

.ant-picker-content .ant-picker-time-panel-column::-webkit-scrollbar {
  display: none;
}
.ant-picker-dropdown .ant-picker-time-panel-column {
  width: 50px;
}

.ant-picker {
  width: -webkit-fill-available;
  padding: 8px 11px 8px;
  border-radius: 10px;
  border: 1px solid #c2c2c2;
}
.ant-picker .ant-picker-suffix {
  display: none;
}
.ant-picker .ant-picker-input > input {
  padding-left: 2.5rem;
}

.ant-picker .ant-picker-input > input::placeholder {
  color: #737373;
}

.react-responsive-modal-modal {
  padding: 2em;
  background-color: #fff !important;
  border-radius: 20px;
}
.react-responsive-modal-root {
  position: absolute;
  left: 50%;
  top: 20%;
}

.ReactModal__Content--after-open {
  padding: 0 !important;
}

.ReactModal__Overlay--after-open {
  z-index: 1010 !important;
}


.Toastify {
  position: absolute;
}

.Toastify__progress-bar--animated {
  opacity: 1 !important;
}

.react-multi-carousel-track {
  gap: 1rem;
}

.react-multiple-carousel__arrow--right {
  right: 2.5rem !important;
}

.react-multi-carousel-list {
  position: initial !important;
}

.react-multiple-carousel__arrow {
  background-color: #fff !important;
  box-shadow: 0px 7.27273px 26.1818px #f4f4f4;
}

.react-multiple-carousel__arrow::before {
  color: #2a2a2a !important;
}
.react-multiple-carousel__arrow--left {
  margin-left: -3.2rem !important;
}

.range-slider .range-slider__range {
  background: #f8a42f !important;
}
.range-slider .range-slider__thumb {
  background: #f8a42f !important;
}
#react-select-16-listbox {
  z-index: 12;
}
