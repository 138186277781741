@font-face {
    font-family: "GWP Bold";
    src: url("../fonts/GTWalsheimPro-Bold.ttf");
  }


  @font-face {
    font-family: "GWP Regular";
    src: url("../fonts/GTWalsheimPro-Regular.ttf");
  }


  @font-face {
    font-family: "GWP Light";
    src: url("../fonts/GTWalsheimPro-Light.ttf");
  }


  @font-face {
    font-family: "GWP Medium";
    src: url("../fonts/GTWalsheimPro-Medium.ttf");
  }
  @font-face {
    font-family: "GWP Thin";
    src: url("../fonts/GTWalsheimPro-Thin.ttf");
  }
  

  $medium: "GWP Medium"
